import React, {Component} from 'react';
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import './Portal.css'
import {Form} from 'react-formio';
import 'formiojs/dist/formio.builder.min.css'



class Portal extends React.Component {

  render() {
    return (
    
        <div class="container-fluid"> 
        <div class="row"> 
        <div class="col-md-4 first-sec"> <h2>
                   Pricaro Health
                </h2><br
></br>                
                <h5><ol>Get Second Opinion in 5 steps :</ol></h5>
                <p>
                    <ol>1. Fill the form and upload all relevent reports</ol>
                    <ol>2. On successful submition, you will recive an email after preliminary examination with surgeons profile and applicability of your financial aid.</ol>
                    <ol>3. Choose your surgeon and Make payment </ol>
                    <ol>4. Attent your consultation on booked time</ol>
                    <ol>5. Get your report</ol>
                </p>    
               </div>
        
            <div class="col-md-8">
                <Form src="https://api.pricaro.com/getvitalvpn/sof" />
            </div>
        </div>
    </div>
    
    )
  }
}

export default Portal