import React, {Component} from 'react';
import Signup from './Signup';
import './Login.css'

class Login extends Component{

render(){
    return(
        <div>

        <div class="container">
            <div class="left">
                <div class="header">
                    <h2 class="animation a1">Login / Signup!</h2>
                    <h4 class="animation a2">Fill the form, attach your  diagnostics and get treatment.</h4> 

                <Signup/>
                </div>
        </div>

        
            <div class="right"></div>
        </div>
        
    </div>
        
                    
        


)




    }

};

export default Login;