import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

// create a web app in fireabse 
// then get your app config object from firebase console -> settings

const firebaseConfig = {
  /*
  apiKey: "AIzaSyBEZ-YU9eQ8e4tzfX_UrKoXXEMdesKzrao",
  authDomain: "auth-9cd20.firebaseapp.com",
  projectId: "auth-9cd20",
  storageBucket: "auth-9cd20.appspot.com",
  messagingSenderId: "339260546833",
  appId: "1:339260546833:web:272962a072fbe3b521b6b0",
  measurementId: "G-F1DY7VSF1X"
  */
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID
  
};

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  firebase.auth();