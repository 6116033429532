import React from 'react';
import './App.css';
import Signup from './Signup';
import Login from './Login';
import Portal from './Portal';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  return (
    <Router>
      

      <div>
        
        <Route exact path="/" component={Login}/> 
        <ProtectedRoute path="/Portal" component = {Portal}/>
        
      </div>
    

    </Router>

    

  );
}

export default App;
